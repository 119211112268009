import MediaQuery from 'lib/media-query'
import { App, PageBase, $$ } from 'frontend/common-imports'
import './rooms-page'
import Glide, { GLIDE_SETTINGS, Autoplay, Lazy, Images, Controls, Swipe, Breakpoints } from 'frontend/lib/glide/bundle'
import conversionSet from 'frontend/lib/conversion/conversion-set'

export default class HomePage extends PageBase {
  static handleDOMReady() {
    conversionSet.push('event', 'view_home_page')
  }

  static promotionsSlider() {
    new Glide('.promotions_slider',
      Object.assign(GLIDE_SETTINGS, { animationDuration: 500, autoplay: 3000 }),
    ).mount({ Swipe, Controls, Lazy })
  }

  static promotionsBanner() {
    const banner = document.getElementById('promotions_banner')
    if (!banner) { return } // If there is no banner we want to do nothing but leave following code for the future
    const button = banner.querySelector('.control_button')
    if (this.promotionsBannerState === 'open') {
      banner.classList.remove('collapsed')
    } else {
      banner.classList.add('collapsed')
    }

    button.addEventListener('click', () => {
      this.promotionsBannerState = this.promotionsBannerState === 'closed' ? 'open' : 'closed'
      banner.classList.add('is_animating')
      banner.classList.toggle('collapsed')
    })
  }

  static get promotionsBannerState() {
    return localStorage?.getItem('promotionsBanner') || 'closed'
  }

  static set promotionsBannerState(value) {
    localStorage?.setItem('promotionsBanner', value)
  }

  static handleEventsSliderRender() {
    if (!this.eventSliderEnteredScreen) { return }
    if (MediaQuery.current.fits('xlarge')) {
      this.destroyEventsSlider()
    } else {
      this.initEventsSlider()
    }
  }

  static async initEventsSlider() {
    if (this.eventSliderInitialized) { return }

    this.eventSliderInitialized = true
    this.eventsSlider = new Glide('.events_slider',
      Object.assign(
        GLIDE_SETTINGS,
        {
          type: 'slider',
          focusAt: 'center',
          startAt: 2,
          breakpoints: {
            480: { perView: 1 },
            768: { perView: 2 },
            1024: { perView: 3 },
            9999: { perView: 5 },
          },
          autoplay: 500,
          lazyGlide: { processImages: true },
        },
      ),
    ).mount({ Images, Swipe, Lazy, Breakpoints })
  }

  static destroyEventsSlider() {
    if (!this.eventSliderInitialized) { return }

    this.eventSliderInitialized = false
    this.eventsSlider.destroy()
  }

  static handleStylesApplied() {
    App.log('HomePage.handleStylesApplied()')
    this.promotionsBanner()

    if ($$('.promotions_slider .slider_slide').length > 1) {
      this.promotionsSlider()
    }

    this.onEnter('.events .events_slider', () => {
      this.eventSliderEnteredScreen = true
      this.handleEventsSliderRender()
    })

    this.onEnter('.newsletter_and_community .newsletter', () => {
      MediaQuery.addListener(() => this.handleEventsSliderRender())
    })
  }
}

HomePage.init()
